<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-user', params: { accountId: this.$route.params.accountId } }">Users</router-link>
                </p>
                </v-col>
            </v-row> -->
        <v-row justify="center" class="py-5 px-10 mt-8" v-if="isViewReady">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <h1 class="text-h6 font-weight-light text-center">{{ displayName }}</h1>
            <!-- <h1 class="text-caption font-weight-light text-center">{{ domain.status }}</h1> -->
            </v-col>
        </v-row>
        <v-row justify="center" class="py-5 px-10" v-if="isViewReady">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <!-- <p class="text-overline mb-0">Registrar</p>
                <template v-if="!whois">
                <p class="mb-0 pb-0">
                    Checking whois...
                </p>
                </template>
                <template v-if="whois">
                <p class="mb-0 pb-0">
                    Registrar:
                    <template v-if="registrarURL">
                        <a :href="registrarURL" target="_blank">{{ registrarName }}</a>
                    </template>
                    <template v-if="!registrarURL">
                        {{ registrarName }}
                    </template>
                    <template v-if="whois.text">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="whoisDialog = true" v-bind="attrs" v-on="on">
                                <font-awesome-icon :icon="['fas', 'info-circle']" class="blue--text"/>
                            </v-btn>
                        </template>
                        See full text
                    </v-tooltip>
                    <v-dialog v-model="whoisDialog">
                        <v-card elevation="4">
                            <v-toolbar short flat color="white">
                                <v-toolbar-title>Whois result for {{ domain.name }}</v-toolbar-title>
                            </v-toolbar>
                            <v-form class="pa-5" onSubmit="return false;">
                            <v-textarea :value="whois.text" rows="10" />
                            </v-form>
                        </v-card>
                    </v-dialog>
                    </template>
                </p>
                </template> -->

                <!-- TODO: account administrator can see if the user has fileserver access, lock/unlock user access to fileserver for *THIS* account; users with access to fileserver can reset their own password or set their own ssh authorized keys in their own user profile settings, only if their access is not locked -->
                    <p class="text-overline mb-0 mt-8">Volumes</p>
                    <p class="mb-0 pb-0">
                        <!-- TODO: list of volumes, and mark which ones user has read-only or read-write access to -->
                        <router-link :to="{ name: 'account-search-volume', params: { accountId: this.$route.params.accountId } }">See all storage volumes</router-link>
                    </p>

                <template v-if="isPermitServiceAdmin">
                <p class="text-overline mb-0 mt-10">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="purple--text"/></p>
                <p class="mb-0 pb-0">
                    <!-- <router-link :to="{ name: 'system-dashboard' }">Manage LibertyDNS</router-link> -->
                    <!-- Edit domain status -->
                    <!-- <v-btn text color="purple" class="px-0" @click="editDomainStatus('verified')" v-if="domain.status !== 'verified'">Verify domain</v-btn>
                    <v-btn text color="purple" class="px-0" @click="editDomainStatus('pending')" v-if="domain.status === 'verified'">Suspend domain</v-btn> -->
                    <!-- TODO: delete user password, delete user authorized keys, delete user -->
                </p>
                </template>
            </v-col>
        </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
// import RealmList from '@/components/domain-dashboard/RealmList.vue';

function nonEmpty(item) {
    return typeof item === 'string' && item.trim().length > 0 ? item : undefined;
}

export default {
    components: {
        // RealmList,
    },
    data: () => ({
        account: null,
        user: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        accountName() {
            return this.isViewReady ? this.account?.name ?? 'Unknown' : '';
        },
        displayName() {
            return this.isViewReady ? nonEmpty(this.user?.display_name) ?? nonEmpty(this.user?.info?.displayName) ?? 'Unknown' : '';
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isViewReady() {
            return this.account !== null && this.user !== null;
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).self.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadUser() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadUser: true });
                const response = await this.$client.account(this.$route.params.accountId).user.get(this.$route.params.userId);
                console.log(`loadUser: response ${JSON.stringify(response)}`);
                if (response) {
                    this.user = response;
                } else {
                    // TODO: redirect back to domain list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load user', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadUser: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadUser();
    },
};
</script>
